import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TwoSideSection from "../../components/twoSideSection"
import HtmlRenderer from "../../components/htmlRenderer"

const WhyAWS = (props) => {
	const data = useStaticQuery(graphql`
		query WhyAWS {
			dnhCms {
				subPages(where: {pageTitle: "Why AWS"}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					richCopy{
						html
					}
					pageBanner {
						url
					}
					twoSideSections {
						id
						headline
						copy{
							html
						}
						image {
							handle
							height
							fileName
							width
						}
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription} />
				<div className="container w820">
					<HtmlRenderer
						htmlData={data.dnhCms.subPages[0].richCopy.html}
						rendererID="js-why-aws-renderer"
						rendererClass="css-renderer padding-lr-16"/>
				</div>
				<TwoSideSection sectionData={data.dnhCms.subPages[0].twoSideSections} />
			</Layout>
		</>
	)
}

WhyAWS.propTypes = {
	location: PropTypes.object
}

export default WhyAWS
