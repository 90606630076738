import React from 'react';
import GraphImg from "graphcms-image";
import HtmlRenderer from "./htmlRenderer"

const TwoSideSection = (sectionData) => (
	<>
		{sectionData.sectionData.map((item, index) => (
			<div key={item.id} className="two-side-wrap">
				<div className="container two-side__item">
					<div className="left-side">
						<header>
							<h3>{item.headline}</h3>
						</header>
						<div className="two-side__copy">
							<HtmlRenderer
								htmlData={item.copy.html}
								rendererID={`js-two-side-renderer-${index}`} />
						</div>
					</div>
					<div className="right-side">
						<div className="two-side__image">
							<GraphImg image={item.image} fit="scale" backgroundColor={true} withWebp={true} alt={item.name} />
						</div>
					</div>
				</div>
			</div>
		))}
	</>
)

export default TwoSideSection
